import React, { useEffect, useState } from 'react';
import { useGetLatestEvents } from 'hooks/useGetLatestEvents';
import { HomeEventsItem } from './HomeEventsItem';
import { EventsType } from 'hooks/useGetLatestEvents';
import { UpcomingEvents } from './UpcomingEvents';

export const HomeEvents: React.FC = () => {

    const { data } = useGetLatestEvents()
    const [soonestEvents, setSoonestEvents] = useState<Array<EventsType>>([])
    const [upcomingEvents, setUpcomingEvents] = useState<Array<EventsType>>([])

    useEffect(() => {
        if (data) {
            const now = new Date()
            const filteredEvents = data.filter((event, i) => !!event.endDate && new Date(event.endDate) > now)

            setSoonestEvents([...filteredEvents].slice(0, 3))
            if (filteredEvents.length > 3) setUpcomingEvents([...filteredEvents].slice(3, 8))
        }

    }, [data])
    
    
    if(data && data.length === 0) return null

    return (
        <div className='HomeEvents'>
            <h2>Upcoming Events</h2>
            <div className="HomeEvents-flex">
                {soonestEvents ? soonestEvents.map((event, i) =>
                    <HomeEventsItem key={i} event={event} />
                ) : ''}
                {upcomingEvents.length > 0 ? <UpcomingEvents events={upcomingEvents} /> : ''}
            </div>
        </div>
    );
}