import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { trimStringToNearestWord } from 'utils/trimStringToNearestWord';
import { LatestNewsQuery } from '../../gatsby-graphql';
import { NewsDatetime } from './NewsDatetime';

export const HomeNews: React.FC = () => {
    const maxTitleChars = 35

    const data: LatestNewsQuery = useStaticQuery(graphql`
        query LatestNews {
            allFile(
                filter: {sourceInstanceName: {eq: "markdown-news"}}
                sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}
                limit: 4
            ) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            frontmatter {
                                title
                                date
                                path
                                hero {
                                    altText
                                    heroImage {
                                        childImageSharp {
                                            ...NewsHomeImage
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            defaults: file(relativePath: {eq: "settings.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        newsHero {
                            altText
                            heroImage {
                                childImageSharp {
                                    ...NewsHomeImage
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const latestItem = data.allFile.edges?.[0]?.node
    const defaultImages = data.defaults?.childMarkdownRemark?.frontmatter?.newsHero
    const latestItemImage = latestItem.childMarkdownRemark?.frontmatter?.hero?.heroImage?.childImageSharp || defaultImages?.heroImage?.childImageSharp

    if (!latestItem) return null

    const latestImage = useMemo(() => {
        if (!latestItemImage) return
        return withArtDirection(getImage(latestItemImage!.gatsbyImageData) as IGatsbyImageData, [
            {
                media: "(min-width: 68em)",
                image: getImage(latestItemImage!.largeImage) as IGatsbyImageData
            },
        ])
    }, [])


    return (
        <section className='HomeNews'>
            <h2>Latest News</h2>
            <div className="HomeNews-newsItems">

                <div className="HomeNews-latestItem HomeNews-item">
                    {latestImage ?
                        <GatsbyImage
                            className="art-directed"
                            image={latestImage}
                            alt={latestItem.childMarkdownRemark?.frontmatter?.hero?.altText || defaultImages?.altText || ''}
                        />
                        : ''
                    }
                    <Link to={latestItem.childMarkdownRemark?.frontmatter?.path || '/news'} className="HomeNews-text">
                        <h3>
                            {latestItem.childMarkdownRemark?.frontmatter?.title}
                        </h3>
                        <div className="HomeNews-time">
                            <NewsDatetime date={latestItem.childMarkdownRemark?.frontmatter?.date} />
                        </div>
                    </Link>
                </div>
                {data.allFile.edges.map((newsItem, i) => {
                    if (i === 0) return
                    const image = newsItem.node.childMarkdownRemark?.frontmatter?.hero?.heroImage?.childImageSharp?.gatsbyImageData || defaultImages?.heroImage?.childImageSharp?.gatsbyImageData
                    const title = newsItem.node.childMarkdownRemark?.frontmatter?.title
                    const date = newsItem.node.childMarkdownRemark?.frontmatter?.date
                    const path = newsItem.node.childMarkdownRemark?.frontmatter?.path
                    return (
                        <Link to={path || '/news'} className="HomeNews-item" key={i}>
                            {image ?
                                <GatsbyImage
                                    image={image}
                                    alt={newsItem.node.childMarkdownRemark?.frontmatter?.hero?.altText || ''}
                                />

                                : ''}
                            <div className="HomeNews-text">

                                <h3>
                                    {trimStringToNearestWord(title || '', maxTitleChars)}
                                    {title && title.length > maxTitleChars ? '...' : ''}
                                </h3>
                                <div className="HomeNews-time">
                                    <NewsDatetime date={date} />
                                </div>
                            </div>
                        </Link>
                    )
                }
                )}
            </div>
        </section>
    );
}