import React from 'react'
import { Link } from 'gatsby';
import { EventsType } from 'hooks/useGetLatestEvents';
import { trimStringToNearestWord } from 'utils/trimStringToNearestWord';
import { GatsbyImage } from 'gatsby-plugin-image';
import { EventDatetime } from './EventDatetime';

export type HomeEventsItemProps = {
    event: EventsType
}

export const HomeEventsItem: React.FC<HomeEventsItemProps> = ({ event }) => {
    const { heroImage, heroAltText, path, title, startDate, endDate } = event
    if (!startDate || !endDate) return <></>

    const maxTitleChars = 30

    return (
        <Link to={path || '/events'} className='HomeEventsItem'>
            {heroImage ?
                <GatsbyImage
                    image={heroImage}
                    style={{width: "100%"}}
                    alt={heroAltText || ''}
                />
                : ''}
            <div className="HomeEventsItem-text">
                <h3>
                    {title}
                </h3>
                <div className="HomeEventsItem-eventTime">
                    <EventDatetime startDate={startDate} endDate={endDate} />                    
                </div>
            </div>
        </Link>
    );
}