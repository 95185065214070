import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { MarkdownRemarkFrontmatterContent_Blocks } from '../../gatsby-graphql';

export type ContentBlockProps = {
    data: MarkdownRemarkFrontmatterContent_Blocks
    index: number
}

export const ContentBlock: React.FC<ContentBlockProps> = ({ data, index }) => (
    <div className={`ContentBlock is-${index % 2 ? 'even' : 'odd'} `}>
        <div className="ContentBlock-image">
            {data?.block_image?.blockImage?.childImageSharp?.gatsbyImageData ?
                <GatsbyImage
                    image={data.block_image.blockImage?.childImageSharp.gatsbyImageData}
                    alt={data.block_image.blockImageAltText || ''}
                />
                : ''}
        </div>
        <div className="ContentBlock-content">
            <h2>{data.blockTitle}</h2>
            <p>{data.blockText}</p>
        </div>

    </div>
);
