import { Link } from 'gatsby';
import { EventsType } from 'hooks/useGetLatestEvents';
import React from 'react'
import { trimStringToNearestWord } from 'utils/trimStringToNearestWord';
import { UpcomingEventDatetime } from './UpcomingEventDatetime';

export type UpcomingEventsProps = {
    events: Array<EventsType>
}

export const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ events }) => {
    const maxChar = 25
    const filteredEvents = events.filter(event => event.startDate)
    return (
        <div className='UpcomingEvents'>
            <h3 className="UpcomingEvents-title">More Upcoming Events</h3>
            {filteredEvents.map((event, i) =>
                <Link to={event.path || '/'} className="UpcomingEvents-event" key={i}>
                    <div className="UpcomingEvents-dateTime">
                        <UpcomingEventDatetime startDate={event.startDate!} />
                    </div>
                    <p className="UpcomingEvents-eventTitle">
                        {trimStringToNearestWord(event.title || '', maxChar)}
                        {event?.title && event.title.length > maxChar ? '...' : ''}
                    </p>
                </Link>
            )}
        </div>
    );
}