import React from 'react'

export type UpcomingEventDatetimeProps = {
    startDate: string
}

export const UpcomingEventDatetime: React.FC<UpcomingEventDatetimeProps> = ({ startDate }) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Pacific/Auckland",
        day: "2-digit",
        month: "2-digit"

    }
    const timeFormatOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Pacific/Auckland",
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }
    return (
        <time className='UpcomingEventDatetime' dateTime={startDate}>
            <span className="UpcomingEventDatetime-date">{new Date(startDate).toLocaleDateString('en-NZ', dateFormatOptions)}</span>
            <span className="UpcomingEventDatetime-time">{new Date(startDate).toLocaleTimeString('en-NZ', timeFormatOptions)}</span>
        </time>
    );
}