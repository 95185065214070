import React from 'react'

export type PageWrapperProps = {
    pageClass?: string
}

export const PageWrapper: React.FC<PageWrapperProps> = ({pageClass = "", children}) => {
        
    return (
        <div className={`PageWrapper ${pageClass}`}>
            {children}
        </div>
    );
}