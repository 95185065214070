import { ClientOnly } from "components/ClientOnly"
import { ContentBlock } from "components/ContentBlock"
import { HeroImageType } from "components/HeroImage"
import { HomeEvents } from "components/HomeEvents"
import { HomeNews } from "components/HomeNews"
import { PageWrapper } from "components/PageWrapper"
import { Seo } from "components/Seo"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { GetHomePageQuery, Maybe } from "../../gatsby-graphql"

export type DataProps = GetHomePageQuery


const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
    const childMarkdownRemark = data.file?.childMarkdownRemark || null
    const { frontmatter } = childMarkdownRemark || { frontmatter: null }
    const heroImageData = frontmatter?.hero?.heroImage?.childImageSharp as Maybe<HeroImageType>

    const images = useMemo(() => {
        if (!heroImageData) return
        return withArtDirection(getImage(heroImageData!.smallImage) as IGatsbyImageData, [
            {
                media: "(min-width: 75em)",
                image: getImage(heroImageData!.largeImage) as IGatsbyImageData
            },
            {
                media: "(min-width: 30em)",
                image: getImage(heroImageData!.mediumImage!) as IGatsbyImageData
            },
        ])
    }, [])



    return (
        <PageWrapper pageClass="HomePage">
            <Seo title="Home Page" />
            <div className='Hero'>
                <div className="Hero-overlay">
                    <div className="inner">
                        <div className="Hero-textWrapper">
                            <h1><span className="red">Samoa</span> International Business Finance Centre</h1>
                            <p>Promotes the International Financial Services and Wealth Management Solutions offered by Samoa</p>
                        </div>
                    </div>
                </div>
                {heroImageData && images ?
                    <GatsbyImage
                        image={images}
                        alt={frontmatter?.hero?.altText || ''}
                        style={{ width: "100%", height: "80vh", maxHeight: "50em", minHeight: "25em" }}
                        loading="eager" />

                    : ''
                }
            </div>
            <div className="inner">
                <HomeNews />
                <ClientOnly>
                    <HomeEvents />
                </ClientOnly>
                {frontmatter?.content_blocks ? frontmatter.content_blocks.map((block, i) =>
                    <React.Fragment key={i}>
                        {block ?
                            <ContentBlock index={i} data={block} />
                            : ''
                        }
                    </React.Fragment>
                ) : ''}



            </div>
        </PageWrapper>
    )
}


export default IndexPage

export const query = graphql`
    query getHomePage {
        file(
            relativePath: {eq: "home-page.md"}
            sourceInstanceName: {eq: "unique-pages"}
        ) {
            childMarkdownRemark {
                frontmatter {
                    title
                    description
                    hero {
                        altText
                        heroImage {
                            childImageSharp {
                                largeImage: gatsbyImageData(
                                    quality: 90
                                    height: 800
                                    width: 2000
                                    transformOptions: {cropFocus: CENTER}
                                    layout: FIXED
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                )
                                mediumImage: gatsbyImageData(
                                    quality: 90
                                    height: 700
                                    width: 1200
                                    transformOptions: {cropFocus: CENTER}
                                    layout: FIXED
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                )
                                smallImage: gatsbyImageData(
                                    quality: 90
                                    height: 500
                                    width: 480
                                    transformOptions: {cropFocus: CENTER}
                                    layout: FIXED
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                )
                            }
                        }
                    }
                    content_blocks {
                        blockTitle
                        blockText
                        block_image {
                            blockImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        quality: 90
                                        transformOptions: {cropFocus: CENTER}
                                        layout: CONSTRAINED
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP]
                                    )
                                }
                            }
                            blockImageAltText
                        }
                    }
                }
            }
        }
    }
`;